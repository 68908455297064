import React from 'react';
import styles from './style.module.scss';

function Footer() {
  return (
    <div className={styles.footer}>
      {/* <p>© 2024 Banana Portal</p> */}
    </div>
  );
}

export default Footer;
